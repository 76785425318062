<template>
    <div class="col-12">
        <div class="card">
            <h5>Produtos a Fixar</h5>			

            <DataTable :value="produtosFixar" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack"
					:globalFilterFields="['codigoItem','codigoReferencia','descricao','quantidadeQuilos','quantidadeSacas']" >
				<template #header>
					<div class="flex justify-content-between flex-column sm:flex-row">
						<Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
						<span class="p-input-icon-left mb-2">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
						</span>
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando... Por favor, aguarde.
				</template>

				<Column style="text-align: center; min-width:2rem">
					<template #body="data">
                   		<Button @click="$emit('showDetail', true, data.data.codigoItem)" type="button" class="mr-2" label="Detalhar" icon="pi pi-search"></Button>						
					</template>
				</Column>

				<Column field="codigoItem" header="Cód. Item" :sortable="true" style="text-align: center; min-width:2rem">
					<template #body="data">
                   		{{data.data.codigoItem}}						
					</template>
				</Column>

				<Column field="descricao" header="Descrição" :sortable="true" style="text-align: left; min-width:12rem">
					<template >
						{{descricao}}
					</template>
				</Column>

				<Column field="codigoReferencia" header="Referência" :sortable="true" style="text-align: center; min-width:6rem">
					<template>
						{{codigoReferencia}}
					</template>
				</Column>

				<Column field="quantidadeQuilos" header="Total quilos" :sortable="true" style="text-align: right; min-width:2rem">
					<template #body="data">
						{{formatDecimal(data.data.quantidadeQuilos,0,0)}} kg
					</template>
				</Column>

				<Column field="quantidadeSacas" header="Total sacas" :sortable="true" style="text-align: right; min-width:2rem">
					<template #body="data">
						{{formatDecimal(data.data.quantidadeSacas)}} sc
					</template>
				</Column>
				<ColumnGroup type="footer">
					<Row>
						<Column footer="Total:" :colspan="4" footerStyle="text-align: right;"/>
						<Column style="text-align: right" :footer="formatDecimal(totalQuilos,0,0) + ' kg'"/> 
						<Column style="text-align: right" :footer="formatDecimal(totalSacas) + ' sc'"/>
					</Row>
				</ColumnGroup>
			</DataTable>
            
        </div>
    </div>

</template>

<script>

	import Formatacao from '../../utilities/Formatacao';
    import {FilterMatchMode,FilterOperator} from 'primevue/api';
    import ProdutosFixarService from "../../service/ProdutosFixarService"

    export default {
        name: 'produtosFixarResumido', 

		props: {
			matricula: {
				type: Number,
				required: true
			}
		},
        data(){
            return {
				filters1: null,
				filters2: {},
				loading1: true,
				loading2: true,
                produtosFixar: [],
				totalQuilos: null,
				totalSacas: null
            }
        },
        created(){
            this.initFilters1();
            this.getProdutoFixarResumido();
        },
		mounted() {
			this.loading1 = false;
			this.loading2 = false;
		},
        methods: {
            initFilters1() {
				this.filters1 = {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'codigoItem': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'codigoReferencia': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
					'quantidadeQuilos': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
					'quantidadeSacas': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
					'descricao': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]}			
				}
			},
            getProdutoFixarResumido() {
                ProdutosFixarService.getProdutosFixarResumido(this.$props.matricula).then((response) => {
                    this.produtosFixar = response.data.content;
                    this.totalQuilos = response.data.quantidadeQuilos;
                    this.totalSacas = response.data.quantidadeSacas;
				}) ;
			},
			clearFilter1() {
				this.initFilters1();
			},
			formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return value ? Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits) : '';
			}

        }
       
    }
    
</script>

<style>
    @import '../../assets/demo/styles/badges.scss';

	.label-blue{
		color: #0066cc;
	}
	.p-datatable-table .p-column-header-content {
        display: inline-flex !important;
    }

</style>

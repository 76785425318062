import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const PRODUTOS_FIXAR_API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/produtos-armazenados-fixar`;

class ProdutoFixarService {
    
    getProdutosFixarResumido(matricula) {
        return axios.get(PRODUTOS_FIXAR_API_BASE_URL + RESOURCE_V1 +"/resumido-item/"+ matricula);
    }

    getProdutosFixarDetalhado(matricula, codigoItem) {
        return axios.get(PRODUTOS_FIXAR_API_BASE_URL + RESOURCE_V1 +"/detalhado/"+ matricula + "/" + codigoItem);
    }

    getProdutosFixarResumoFamilia(matricula) {
        return axios.get(PRODUTOS_FIXAR_API_BASE_URL + RESOURCE_V1 + "/resumido-familia/" + matricula);
    }
}

export default new ProdutoFixarService();
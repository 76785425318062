<template>
    <ProdutosFixarResumido v-if="!showDetalhe" v-on:showDetail="showDetail" :matricula="currentUser.matricula"/>
    <ProdutosFixarDetalhado v-if="showDetalhe" v-on:showDetail="showDetail" :matricula="currentUser.matricula" :codigoItem="codigoItem"/>
</template>

<script lang="js">
    
    import ProdutosFixarResumido from '../../components/extrato/ProdutosFixarResumido.vue';
    import ProdutosFixarDetalhado from '../../components/extrato/ProdutosFixarDetalhado.vue';

    export  default {
        data(){
            return {
                showDetalhe: false,
                codigoItem: null
            }
        },
        props: {
            conta: Object
        },
        methods: {
            showDetail(value, codigoItem) {
                this.showDetalhe = value;
                if(value){
                    this.codigoItem = codigoItem;
                }
            }
        },
		computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { ProdutosFixarResumido, ProdutosFixarDetalhado}
    }

</script>
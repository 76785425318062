<template>
    <div class="col-12">
        <div class="card">
            <h5>Produtos a fixar detalhado</h5>
			<DataTable :value="produtosFixar" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack"
					:globalFilterFields="['codigoEstabelecimentoEntrega','numeroRomaneioEntrega','data','numeroTicketPesagem','matricula',
					'codigoRegional','codigoItem','codigoReferencia','descricaoItem','numeroCadastroProdutorRuralOuICMS','saldo','valorProduto','valorTotal']" >
				<template #header>
					<div class="flex justify-content-between flex-column sm:flex-row">
						<div>
							<Button @click="$emit('showDetail', false)" icon="pi pi-backward" type="button" class="p-button-outlined mb-2" label="Voltar"/>
							<Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
						</div>
						<span class="p-input-icon-left mb-2">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
						</span>
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando... Por favor, aguarde.
				</template>
				<Column field="data" header="Data" :sortable="true" style="text-align: center; min-width:2rem">
					<template #body="data">
						{{formatarData(data.data.data)}}
					</template>
				</Column>
				<Column field="numeroTicketPesagem" header="Ticket" :sortable="true" style="text-align: center; min-width:2rem">
					<template #body="data">
						{{data.data.numeroTicketPesagem}}
					</template>
				</Column>
				<Column field="numeroRomaneioEntrega" header="RE" :sortable="true" style="text-align: center; min-width:2rem">
					<template #body="data">
						{{data.data.numeroRomaneioEntrega}}
					</template>
				</Column>
				<Column field="codigoItem" header="Cód. Item" :sortable="true" style="text-align: center; min-width:3rem">
					<template #body="data">
						{{data.data.codigoItem}}
					</template>
				</Column>
				<Column field="descricaoItem" header="Descrição" :sortable="true" style="text-align: left; min-width:12rem">
					<template >
						{{descricaoItem}}
					</template>
				</Column>
				<Column field="codigoReferencia" header="Referência" :sortable="true" style="text-align: center; min-width:6rem">
					<template >
						{{codigoReferencia}}
					</template>
				</Column>
				<Column field="numeroCadastroProdutorRuralOuICMS" header="CAD-PRO/ICMS" :sortable="true" style="text-align: center; min-width:12rem">
					<template >
						{{numeroCadastroProdutorRuralOuICMS}}
					</template>
				</Column>
				<Column field="saldo" header="Saldo(kg)" :sortable="true" style="text-align: right; min-width:6rem">
					<template #body="data">
						{{formatDecimal(data.data.saldo)}}  kg
					</template>
				</Column>
				<Column field="valorTotal" header="Total" :sortable="true" style="text-align: right; min-width:6rem">
					<template #body="data">
						{{formatCurrency(data.data.valorTotal)}}
					</template>
				</Column>

				<ColumnGroup type="footer">
					<Row>
						<Column footer="Total:" :colspan="7" footerStyle="text-align: right;"/>
						<Column :footer="formatDecimal(totalSaldo)" footerStyle="text-align: right;"/>
						<Column :footer="formatCurrency(totalGeral)" footerStyle="text-align: right;"/>
					</Row>
				</ColumnGroup>
			</DataTable>
            
        </div>
    </div>

</template>

<script>
    import {FilterMatchMode,FilterOperator} from 'primevue/api';
    import ProdutosFixarService from "../../service/ProdutosFixarService"
	import Formatacao from '../../utilities/Formatacao';


    export default {
        name: 'produtosFixarDetalhado', 

		props: {
			matricula: {
				type: Number,
				required: true
			},
			codigoItem: {
				type: String,
				required: true
			}
		},
        data(){
            return {
				filters1: null,
				filters2: {},
				loading1: true,
				loading2: true,
                produtosFixar: [],
				totalGeral: null,
				totalSaldo: null
            }
        },

        created(){
            this.initFilters1();
            this.getProdutosFixarDetalhado();
        },
		mounted() {
			this.loading1 = false;
			this.loading2 = false;
		},
        methods: {
            initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'codigoEstabelecimentoEntrega': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'data': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'numeroCadastroProdutorRuralOuICMS': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'numeroTicketPesagem': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'matricula': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'codigoRegional': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},		
					'codigoItem': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},		
					'codigoReferencia': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},		
					'descricaoItem': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},		
					'saldo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},		
					'valorProduto': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'valorTotal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]}					
				}
			},
            getProdutosFixarDetalhado() {
                ProdutosFixarService.getProdutosFixarDetalhado(
					this.$props.matricula,
					this.$props.codigoItem
					).then((response) => {                    
						this.produtosFixar = response.data.content;
						this.totalGeral = response.data.valorTotal;
						this.totalSaldo = response.data.saldo;
                }) ;
            },
			clearFilter1() {
				this.initFilters1();
			},
			formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits) ;
			},
			formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
			},
			formatarData(data) {                
               	var options = { year: 'numeric', month: 'numeric', day: 'numeric' }; options.timeZone = 'UTC';             
            	return new Date(data).toLocaleDateString("pt-BR", options);
			}
        }
       
    }
    
</script>

<style>
    @import '../../assets/demo/styles/badges.scss';

    button{
		margin-right: 8px;
	}
	.p-datatable-table .p-column-header-content {
        display: inline-flex !important;
    }
	
</style>
